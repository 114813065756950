<template>
  <div>
    <div class="card-title mb-3">
      {{ $t("role.planning.closing.title") }}
    </div>
    <div>
      <TB :tb_config="tb">
        <e-columns>
          <e-column
            field="id"
            headerText="ID"
            type="number"
            :isPrimaryKey="true"
            is-identity="true"
            :allowEditing="false"
            clipMode="EllipsisWithTooltip"
            :visible=false
          ></e-column>
          <e-column
            field="name"
            headerText="Nome"
            clipMode="EllipsisWithTooltip"
          ></e-column>
          <e-column
            field="start"
            headerText="Dal"
            clipMode="EllipsisWithTooltip"
            editType="datepickeredit"
            type="date"
            :format="tsFormat"
          ></e-column>
          <e-column
            field="end"
            headerText="Al"
            clipMode="EllipsisWithTooltip"
            editType="datepickeredit"
            type="date"
            :format="tsFormat"
          ></e-column>
        </e-columns>
      </TB>
    </div>
  </div>
</template>
<script>
import { GetDataManagerNew } from "../../../ds/index";
import TB from "../../../common/TB";
export default {
  components: {
    TB,
  },
  data() {
    let table_config = {
        data: GetDataManagerNew("role_planningclose", [
          this.$store.state.role.id,
        ]),
        searchOptions: {
          fields: [
            "name",
            "id",
          ],
        },
        reload_on_save: true,
      };
    if (this.$store.getters["connections/havePermission"]("LOGISTICS_EDIT")){
        table_config['editSettings']= {
          allowEditing: true,
          allowAdding: true,
          allowDeleting: true,
          mode: "Normal",
        };
        table_config['toolbar']= ["Add", "Edit", "Update", "Cancel"];
    }else{
      table_config['editSettings']= {
          allowEditing: false,
          allowAdding: false,
          allowDeleting: false,
          mode: "Normal",
        };
        table_config['toolbar']= [];
    }
    return {
      tb: table_config,
      tsFormat: { type: "dateTime", format: "dd/MM/yyyy" },
    };
  },
};
</script>
